<template>
	<div>
		<img src="../../../assets/imges/index/1.png" class="headerimg" />
		<div class="LatestActivity">

			<div class="header">
				<span class="'activity'"
				 @click="toactivity">秒杀活动</span>
				<span class="bond Selected" @click="getlist('bond')">限量神劵</span>
			</div>
		
			<div class="card">
					
				<div class="cardlist" v-for="(item,index) in activitylist" :key='index'>
					<div class="cardback">
						<div class="title" v-if="item.couponsRecipientType == '新用户'">新人专享</div>
						<div class="bondinfo">
							<div class="cardname">{{item.name}}</div>
							<div class="cardinfo">
								<div class="price" v-if="item.couponsTypeid == 2">¥{{item.amountKnowledge}}</div>
								<div class="price" v-else>{{item.discount}}折</div>
								<div class="info">
									<div v-if="item.orderAmount > 0" class="text">满{{item.orderAmount}}元使用</div>
									<div v-if="item.couponsScopeApplication == '全部' || item.children.length == 0" class="text">全场通用</div>
									<div v-else-if="item.children.length == 1" class="text" @click="topage(item.children[0])" style="cursor: pointer;">
										{{item.children[0].name}} 
									</div>
									
									<div style="float: left;position: absolute;"  v-else class="text2">
										<el-popover
										  placement="right"
										  width="132" style="float: left;"
										 >
										  <span v-for="(info,index) in item.children" :key="index" class="infoname" @click="topage(info)">{{info.name}}</span>
										  <div  style="cursor: pointer;" slot="reference">
										  	部分使用 <i class="el-icon-arrow-right" ></i>
										  </div>
										</el-popover>
									</div>
								
								</div>
							</div>
							<div class="time">有效期至{{item.endDateFormat}}</div>
						</div>
					</div>
					<div class="activitybutton">
						<el-button type="primary" plain>立即领取</el-button>
					</div>
				</div>
				<div class="cardlist2"></div>
				<div class="cardlist2"></div>
			<!-- 	<div class="cardlist2"></div>
				<div class="cardlist2"></div>
				<div class="cardlist2"></div> -->
				<!-- <div class="cardlist2"></div> -->
			</div>
		</div>
	</div>
</template>

<script>
	import countdown from '../../../components/countdown.vue'
	import {CouponsProductsList} from '../../../api/index.js'
	export default {
		name: "Home",
		components:{
			countdown
		},
		data() {
			return {
				activitylist: [],
				bondlist: [],
			};
		},
		mounted() {},
		created() {
			CouponsProductsList().then(res=>{
				this.activitylist = res.data.items
			})
		},
		methods: {
			toactivity() {
				this.$router.push('activity')
			},
			topage(val){
				this.$router.push(val.productContentUrl)
			}
		},
	};
</script>

<style scoped lang="less">
	.infoname{
		margin-left: 16px;
		font-size: 12px;
		font-weight: 500;
		color: #2F7FFC;
		line-height: 18px;
		cursor: pointer;
	}
	.el-button--primary.is-plain {
		color: #409eff;
		background: #fff;
		border-color: #b3d8ff;
	}

	.el-button--primary.is-plain:hover {
		color: #fff;
		background: #409eff;
		border-color: #409eff;
	}

	.LatestActivity {
		width: 80%;
		margin: 0 auto;

		.header {
			text-align: center;
			font-size: 28px;
			font-family: SourceHanSansCN-Bold, SourceHanSansCN;
			font-weight: bold;
			border-bottom: 1px solid #979797;
			cursor: default;
			height: 120px;
			line-height: 120px;

			span {
				padding-bottom: 16px;
			}

			.bond {
				margin-left: 154px;
			}

			.Selected {
				border-bottom: 8px solid #2f7ffc;
				color: #2f7ffc;
			}
		}

		.card {
			padding: 111px 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			// box-sizing: border-box;
			.cardlist {
				margin-bottom: 70px;
				// margin-left: 70px;
				.cardback {
					height: 231px;
					width: 430px;
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
					border-radius: 10px;
					background-image: url(../../../assets/activity/background.png);
					background-size: cover;

					.title {
						width: 108px;
						height: 33px;
						background: #d92c54;
						border-radius: 0px 11px 0px 11px;
						color: #fff;
						font-size: 16px;
						text-align: center;
						line-height: 30px;
						float: right;
					}

					.activityinfo {
						padding-top: 31px;
						padding-left: 30px;

						.cardname {
							font-size: 28px;
							font-family: Medium;
							font-weight: 500;
							color: #3d88fc;
						}

						.cardinfo {
							font-size: 16px;
							font-family: Medium;
							font-weight: 500;
							color: #9e9e9e;
							margin-top: 26px;
						}

						.cardtag {
							margin-top: 8px;

							span {
								color: #2f7ffc;
								border-radius: 4px;
								background: #eef4ff;
								font-size: 12px;
								line-height: 18px;
								padding: 2px 5px;
								margin-right: 14px;
							}
						}

						.cardtime {
							// margin-top: 37px;
							font-size: 10px;

							.endtime {
								font-size: 14px;
								font-family: Medium;
								font-weight: 500;
								color: #333330;
								margin-right: 6px;
							}

							.time {
								padding: 2px;
								background: #ff825c;
								border-radius: 1px;
								font-size: 10px;
								font-family: Medium;
								font-weight: 500;
								color: #ffffff;
								margin: 0 2.5px;
							}

							.price {
								font-size: 12px;
								font-family: SourceHanSansCN-Bold, SourceHanSansCN;
								font-weight: bold;
								color: #d92c54;
								margin-left: 69px;

								.pricenum {
									font-size: 18px;
								}
							}

							.originalprice {
								font-size: 10px;
								font-family: Medium;
								font-weight: 500;
								color: #9e9e9e;
								text-decoration: line-through;
								margin-left: 14px;
							}
						}
					}

					.bondinfo {
						padding-top: 33px;
						padding-left: 47px;

						.cardname {
							font-size: 28px;
							font-family: Medium;
							font-weight: 500;
							color: #4c4435;
						}

						.cardinfo {
							margin-top: 32px;
							display: flex;

							.price {
								font-size: 32px;
								font-family: SourceHanSansCN-Bold, SourceHanSansCN;
								font-weight: bold;
								color: #d92c54;
								line-height: 48px;
								padding: 19px 22px 19px 0;
							}

							.info {
								font-size: 18px;
								font-family: Medium;
								font-weight: 500;
								color: #2f7ffc;
								line-height: 47px;
								padding-left: 31px;
								border-left: 1px solid #d9d9d9;
								.text2{
									margin-left: -18px;
								}
								span{
									padding-left: 16px;
									color: #2F7FFC;
								}
							}
						}

						.time {
							font-size: 14px;
							font-family: Medium;
							font-weight: 500;
							color: #9e9e9e;
							float: right;
							padding-right: 32px;
						}
					}
				}

				.activitybutton {
					margin-top: 23px;
					float: right;
				}
			}
		}
	}
	.cardlist2{
		height: 8px;
		width: 430px;
	}
</style>
